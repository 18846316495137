import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ApplyPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className="py-10 pt-md-11 bg-dark">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 text-center text-white">
              <h6 className="text-uppercase text-warning mb-5">
                Welcome to Reno Collective
              </h6>

              <h2 className="display-3 mb-4">Apply for Membership</h2>

              <p className="font-size-lg">Thank you for your interest in becoming a member at Reno Collective! </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-10 pt-md-12 mb-10">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
            <p className="font-size-lg">We're excited to learn a little bit more about you. Fill out the application below and we will review your application and contact you about next steps.</p>
            <p className="font-size-lg">If you have any questions or comments, you can contact us at <a href="mailto:hello@renocollective.com">hello@renocollective.com</a>.</p>
              <form
                name="membership-application"
                action="/success"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="membership-application" />
                <div hidden>
                  <input name="bot-field" />
                </div>

                {/* Form fields */}
                <div className="form-group mb-5">
                  <label htmlFor="name">First Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    required
                  />
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="name">Last Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    required
                  />
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="email">Email Address *</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    required
                  />
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="phone">Phone Number *</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    required
                  />
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="workingOn">What will you be working on at Reno Collective? *</label>
                  <textarea
                    className="form-control"
                    id="workingOn"
                    name="workingOn"
                    rows="2"
                    required
                  ></textarea>
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="resourcesNeeded">What kind of resources do you need to be productive & successful? *</label>
                  <textarea
                    className="form-control"
                    id="resourcesNeeded"
                    name="resourcesNeeded"
                    rows="2"
                    required
                  ></textarea>
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="aboutYou">Tell us about yourself and why you'd like to become a member at Reno Collective. *</label>
                  <textarea
                    className="form-control"
                    id="aboutYou"
                    name="aboutYou"
                    rows="2"
                    required
                  ></textarea>
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="howOften">How often do you plan on coming into the space? *</label>
                  <textarea
                    className="form-control"
                    id="howOften"
                    name="howOften"
                    rows="2"
                    required
                  ></textarea>
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="phoneCalls">How many hours a day do you generally need to be on a phone or video call? *</label>
                  <textarea
                    className="form-control"
                    id="phoneCalls"
                    name="phoneCalls"
                    rows="2"
                    required
                  ></textarea>
                </div>

                <div className="form-group mb-5">
                  <label htmlFor="howDidYouHear">How did you hear about us? *</label>
                  <textarea
                    className="form-control"
                    id="howDidYouHear"
                    name="howDidYouHear"
                    rows="2"
                    required
                  ></textarea>
                  <small className="form-text text-muted">If a current member referred you, please put their full name here.</small>
                </div>

                <button type="submit" className="btn btn-primary">
                  Submit Application
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

ApplyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ApplyPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ApplyPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

ApplyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ApplyPage

export const applyPageQuery = graphql`
  query ApplyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
